





















































































































































































































































































































































































































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import { ActivityRecord, ParticipantInfo } from "@/data/models/ActivityRecord";
import { Activity } from "@/data/models/Activity";
import { Gender } from "@/data/models/Genders";
import { Language } from "@/data/models/Languages";
import { Nationality } from "@/data/models/Nationalities";
import { Organization } from "@/data/models/Organizations";
import { User } from "@/data/models/Users";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import DatePicker from "@/components/DatePicker.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import { readFileAsDataUrlAsync } from "@/lib/readFile";
import FileInput from "@/components/FileInput.vue";
import { Guid } from "guid-typescript";
import SubArrayForm from "../SubArrayForm.vue";
import CompaniesForm from "@/components/forms/CompaniesForm.vue";
import { warn } from "vue-class-component/lib/util";
import { saveAs } from "file-saver";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker,
    FileInput,
    SubArrayForm,
    CompaniesForm
  }
})
export default class ActivityTypesForm extends Vue {
  private enumForm = EnumForm;

  @Prop()
  public id!: string;

  public provider!: DataProvider<ActivityRecord>;

  public projectsProvider!: DataProvider<any>;
  public activitiesProvider!: DataProvider<Activity>;
  public usersProvider!: DataProvider<User>;

  @Prop()
  public crud!: ICrudClient<Activity>;

  private activities: any[] | null = null;
  private users: any[] | null = null;
  private projects: any[] | null = null;
  private exactParticipants = false;
  private documents: [] = [];
  private companies: [] = [];
  private selectedActivityType: any | null = null;
  private selectedActivity: any = null;
  private companyDialog: any = false;

  created() {
    this.provider = this.$service.providers.activityRecords;
    this.activitiesProvider = this.$service.providers.activities;
    this.projectsProvider = this.$service.providers.projects;
    this.usersProvider = this.$service.providers.users;
  }

  @Watch("documents", { immediate: false })
  onFilesChanged() {
    if (!this.item) {
      return;
    }
    this.item.documents = this.documents.map((f: any) => f.file);
  }
  validate(i: any) {
    return i && !!i.file.name;
  }

  @Watch("item", { deep: false, immediate: false })
  async onItemChanged(newVal: any, oldVal: any) {
    if (!this.item.documents) {
      return;
    }
    this.documents = this.item
      ? this.item.documents.map((f: any) => ({ file: f }))
      : [];
  }

  @Watch("exactParticipants", { immediate: true })
  onExactParticipantsChanged(val: boolean) {
    if (!this.item) {
      return;
    }
    //this.item.activityParticipants = [];
  }

  private item: any = null;
  @Watch("item", { immediate: true })
  onItemSet() {
    if (this.item && this.item.activity && this.item.activity.activityType) {
      this.selectedActivityType = this.item.activity.activityType;
      this.selectedActivity = this.item.activity;
    }
  }
  @Watch("item.activityParticipants", { deep: true, immediate: true })
  onParticipantsChanged(participants: ParticipantInfo[]) {
    if (!this.item) {
      return;
    }
    if (participants && participants.length) {
      this.exactParticipants = true;
      const total = participants.length;
      const male = participants.filter(
        p => p.gender && p.gender.value && p.gender.value == "Male"
      ).length;
      const female = participants.filter(
        p => p.gender && p.gender.value && p.gender.value == "Female"
      ).length;
      const other = total - male - female;
      this.item.noOfParticipants = this.calculateParticipants;
      this.item.noOfMaleParticipants = male;
      this.item.noOfFemaleParticipants = female;
      this.item.noOfOtherGenderParticipants = other;
    }
  }
  @Watch("item.noOfMaleParticipants", { deep: true, immediate: true })
  onnoOfMaleParticipantsChanged(participants: ParticipantInfo[]) {
    if (!this.item) {
      return;
    }
    this.item.noOfParticipants = this.calculateParticipants;
  }

  @Watch("item.noOfFemaleParticipants", { deep: true, immediate: true })
  onnoOfFemaleParticipantsChanged(participants: ParticipantInfo[]) {
    if (!this.item) {
      return;
    }
    this.item.noOfParticipants = this.calculateParticipants;
  }
  @Watch("item.noOfOtherGenderParticipants", { deep: true, immediate: true })
  onnoOfOtherGenderParticipantsChanged(participants: ParticipantInfo[]) {
    if (!this.item) {
      return;
    }
    this.item.noOfParticipants = this.calculateParticipants;
  }

  @Watch("selectedActivityType", { immediate: true })
  async onSelectedActivityTypeChanged(val: any) {
    if (val && val.id) {
      this.activities = (
        await this.activitiesProvider.fetchItemsAsync({
          columnFilters: [
            {
              column: "ActivityTypeId",
              value: `${val.id}`,
              op: "Equals"
            }
          ]
        })
      ).items;
    }
    if (this.item && this.item.activityId) {
      if (!this.activities?.some(a => a.id == this.item.activityId)) {
        delete this.item.activityId;
      }
    }
  }

  @Watch("item.noOfParticipants", { immediate: false })
  calculateParticipants() {
    this.item.noOfParticipants =
      this.item.noOfMaleParticipants +
      this.item.noOfFemaleParticipants +
      this.item.noOfOtherGenderParticipants;
  }

  async mounted() {
    if (this.id === "new") {
      this.item = {
        topic: "",
        comments: "",
        dateOfSession: new Date().toISOString(),
        endDate: null,
        facilitator: null,
        interpreterLanguage: null,
        program: null,
        originalAttendanceDocument: null,
        activityParticipants: [],
        noOfMaleParticipants: 0,
        noOfFemaleParticipants: 0,
        noOfOtherGenderParticipants: 0,
        noOfParticipants: 0,
        documents: [],
        activityType: [],
        activityStatus: "",
        representative: "",
        trainingEvaluation: "",
        targetGroup: "",
        evaluationAttachment: "",
        companies: []
      };
    }
    this.users = (await this.usersProvider.fetchItemsAsync()).items;
    this.projects = (await this.projectsProvider.fetchItemsAsync()).items;
  }

  removeCompany(company: any) {
    this.item.companies = this.item.companies.filter(
      (x: any) => x.id !== company.id
    );
  }

  bindNewItem(e: any) {
    this.item.companies.push(e);
    if (this.$refs.select) {
      this.$nextTick(() => (this.$refs.select as any).loadOptions());
    }
    this.companyDialog = false;
  }

  async extract() {
     const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.extract(
        "activityRecords/participants",
        null,
        this.id
      ),
      `${this.item.topic}_Participants_${utc}.pdf`
    );
  }
}
