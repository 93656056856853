





















































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { Guid } from "guid-typescript";
import EntitySelect from "@/components/EntitySelect.vue";

@Component({
  components: {
    FormContainer,
    EntitySelect,
    Loader
  }
})
export default class CompaniesForm extends Vue {
  @Prop()
  public id!: string;
  @Prop()
  public name!: string;

  @Prop()
  public routeAfterSave!: (id: any) => Location;

  @Prop()
  public routeAfterSaveAndClose!: (id: any) => Location;
  @Prop()
  public provider!: DataProvider<any>;
  @Prop()
  public crud!: ICrudClient<any>;

  @Prop()
  public inDialog!: boolean;

  private item: any = null;

  async mounted() {
    if (this.id === "new") {
      this.item = {
        phoneNumber: "",
        details: "",
        employmentSectors: []
      };
    }
  }

  async submit() {
    if (this.id === "new") {
      const result = await this.$service.crud.companies.addAsync([this.item]);
      this.$emit("new-item", result[0]);
    } else {
      await this.$service.crud.companies.saveAsync(this.item);
    }
  }
}
