var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.internalArray)?_c('div',[(!_vm.sortable)?_c('div',_vm._l((_vm.internalArray),function(p,i){return _c('div',{key:p.id,staticClass:"row q-col-gutter-sm q-py-sm items-center"},[_c('div',{staticClass:"col"},[_vm._t("form",null,{"item":p,"index":i,"array":_vm.internalArray})],2),_c('div',{staticClass:"col-auto"},[(!_vm.readonly)?_c('q-btn',{staticClass:"q-mr-sm",style:({
            visibility: i === _vm.internalArray.length - 1 ? 'hidden' : 'visible',
          }),attrs:{"round":"","size":"sm","color":"red-5","icon":"delete"},on:{"click":function($event){return _vm.remove(i)}}}):_vm._e()],1)])}),0):_c('div',[_c('draggable',_vm._b({on:{"end":_vm.assignPriority},model:{value:(_vm.internalArray),callback:function ($$v) {_vm.internalArray=$$v},expression:"internalArray"}},'draggable',{
        filter: 'input',
        animation: 300,
        preventOnFilter: false,
        handle: '.handle',
      },false),_vm._l((_vm.internalArray),function(p,i){return _c('div',{key:p.id,class:['row', 'q-col-gutter-sm', 'q-py-sm', 'items-center']},[_c('div',{staticClass:"col"},[_vm._t("form",null,{"item":p,"index":i})],2),_c('div',{staticClass:"col-auto"},[_c('q-btn',{style:({
              visibility:
                i === _vm.internalArray.length - 1 ? 'hidden' : 'visible',
            }),attrs:{"round":"","size":"sm","color":"red-5","icon":"delete"},on:{"click":function($event){return _vm.remove(i)}}})],1)])}),0)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }