










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Loader from "./Loader.vue";
import { readFileAsDataUrlAsync } from "@/lib/readFile";
import { saveAs } from "file-saver";
import { caseManagementReportOptions } from "./reports/reportsSelectOptions";

@Component({
  components: {
    Loader
  }
})
export default class FileInput extends Vue {
  @Prop()
  public value: any;

  @Prop()
  public emitRawFile!: boolean;

  @Prop()
  public downloadFunc!: () => void;

  @Watch("value", { immediate: true })
  onFileChanged() {
    if (this.value) {
      this.typeOfFile = this.value.fileType;
    }

    if (this.value) {
      this.rawFile = new File([], this.value.name);
      this.$forceUpdate();
    } else {
      this.rawFile = null;
    }
  }

  private fileType: object[] = [
    { key: 0, text: "General" },
    { key: 1, text: "Legal" },
    { key: 2, text: "Psycosocial" }
  ];

  private rawFile: File | null = null;
  private typeName = "General";
  private typeOfFile = "General";
  private contents!: any;
  private currentFile: File | null = null;

  private async setFile(file: File) {
    this.currentFile = file;
    this.contents = await readFileAsDataUrlAsync(file);
    this.$emit("input", {
      fileType: this.typeOfFile,
      name: file.name,
      base64Contents: this.contents
    });
    if (this.emitRawFile) {
      this.$emit("file", file);
    }
  }

  async download(s: string) {
    if (!this.downloadFunc) {
      this.downloadBlob(await this.$service.downloadFile(s), s);
    } else {
      this.downloadFunc();
    }
  }
  private downloadBlob(blob: Blob, name: string) {
    saveAs(
      blob,
      name
        .split("-")
        .slice(5)
        .join("-")
    );
  }

  bindTypeOfFile(e: any) {
    this.typeOfFile = e.text;
    if (this.value) {
      this.value.fileType = this.typeOfFile;
    }
  }
}
