






































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import draggable from "vuedraggable";
import { Guid } from "guid-typescript";

@Component({
  components: {
    draggable,
  },
})
export default class SubArrayForm extends Vue {
  @Prop()
  public array!: any[];

  @Prop({ default: false })
  public sortable!: boolean;

  @Prop({ default: false })
  public readonly!: boolean;

  @Prop()
  public generator!: (id: string) => any;

  private internalArray: any[] | null = null;
  mounted() {
    this.onArrayChanged();
    this.onInternalArrayChanged();
  }
  private remove(i: any) {
    const found = this.array[i];
    this.array.splice(this.array.indexOf(found), 1);
    this.$emit("remove", found);
  }

  getNewId() {
    return Guid.create().toString();
  }
  @Prop()
  public validateObject!: (id: any) => boolean;
  hasAllPropertiesFilled(item: any) {
    if (item === "") {
      return false;
    }
    let bool = true;
    for (const k of Object.keys(item)) {
      bool =
        bool && item[k] !== undefined && item[k] !== "" && item[k] !== null;
    }

    return bool;
  }

  @Watch("array")
  onArrayChanged() {
    if (!this.array) {
      return;
    }
    this.internalArray = [...this.array];
  }

  @Watch("internalArray", { deep: true })
  onInternalArrayChanged() {
    if (!this.internalArray || !this.generator) {
      return;
    }
    const newItem = this.generator(this.getNewId());
    if (this.internalArray.length === 0) {
      //this.$emit("add", newItem);
      this.internalArray.push(newItem);
      return;
    }
    let bool = true;
    for (const item of this.internalArray) {
      if (!this.validateObject) {
        bool = bool && this.hasAllPropertiesFilled(item);
      } else {
        bool = bool && this.validateObject(item);
      }
    }
    if (bool && !this.readonly) {
      if (this.internalArray.length > this.array.length) {
        this.$emit("add", this.internalArray[this.internalArray.length - 1]);
        this.array.push(this.internalArray[this.internalArray.length - 1]);
      }
      this.internalArray.push(newItem);
      this.assignPriority();
    }
  }
  assignPriority() {
    if (!this.internalArray) {
      return;
    }
    for (let i = 0; i < this.internalArray.length; i++) {
      this.internalArray[i].priority = i;
    }
  }
}
