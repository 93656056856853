function createReader(
  resolve: (res: any) => any,
  reject: (res: any) => any
): FileReader {
  const reader = new FileReader();
  reader.onload = () => {
    resolve(reader.result?.toString() || "");
  };
  reader.onerror = reject;
  return reader;
}

function readFileAsDataUrlAsync(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = createReader(resolve, reject);
    reader.readAsDataURL(file);
  });
}

function readFileAsTextAsync(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = createReader(resolve, reject);
    reader.readAsText(file);
  });
}

export { createReader, readFileAsDataUrlAsync, readFileAsTextAsync };
