








































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Guid } from "guid-typescript";
@Component({
  components: {}
})
export default class EntitySelect extends Vue {
  @Prop()
  public provider!: DataProvider<any>;

  @Prop({ default: () => false })
  public multiple!: boolean;

  @Prop()
  public crud!: ICrudClient<any>;

  @Prop({ default: () => (item: any) => (item ? item.value : null) })
  public optionLabel!: (item: any) => any;

  @Prop({
    default: () => (item: any) => (item && item.id ? item.id : null)
  })
  public optionValue!: (item: any) => any;

  @Prop({ default: () => ({ id: null, value: null }) })
  public nullElement!: any;

  @Prop()
  public value!: any;

  @Prop({ default: () => false })
  public emitValue!: boolean;

  @Prop()
  public component!: any;

  @Prop()
  public oDataArgs!: any;

  @Prop()
  public isRequired!: boolean;

  private loading = false;

  private options: readonly any[] | null = null;
  private filteredOptions: readonly any[] | null = null;
  private showAdd = false;
  innerValue: any | null = null;

  @Watch("provider", { immediate: true })
  async onProviderChanged() {
    await this.loadOptions();
  }

  @Watch("innerValue")
  onInnerValueChanged() {
    this.$emit(
      "input",
      this.innerValue === this.nullElement ? null : this.innerValue
    );
    this.$emit("update:value", this.optionValue(this.innerValue));
  }

  @Watch("value", { immediate: true })
  onValueChanged() {
    this.innerValue = this.value;
  }

  async itemAdded(item: any) {
    await this.loadOptions();
    if (this.options) {
      const f = this.options.filter((o: any) => {
        return this.optionValue(o) === this.optionValue(item);
      });
      if (f.length === 1) {
        const emit = this.emitValue ? this.optionValue(f[0]) : f[0];
        this.$emit("input", this.multiple ? [...this.value, emit] : emit);
        this.$emit(
          "update:value",
          this.multiple
            ? [...this.value, this.optionValue(f[0])]
            : this.optionValue(f[0])
        );
      }
    }
  }

  async loadOptions() {
    if (!this.provider) {
      return;
    }
    try {
      this.loading = true;
      this.options = Object.freeze([
        ...(this.nullElement != null && this.multiple === false && !this.isRequired
          ? [this.nullElement]
          : []),
        ...(await this.provider.fetchItemsAsync(this.oDataArgs)).items
      ]);
      this.filteredOptions = this.options;
    } finally {
      this.loading = false;
    }
  }
  async filterFn(val: any, update: any) {
    if (this.options === null) {
      await this.loadOptions();
    }
    if (val === "") {
      update(() => {
        this.filteredOptions = this.options;
      });
      return;
    }

    update(
      () => {
        const needle = val.toLowerCase();
        if (this.options) {
          this.filteredOptions = this.options.filter(
            v =>
              this.optionLabel(v)
                ?.toLowerCase()
                ?.indexOf(needle) > -1
          );
        }
      },
      (ref: any) => {
        if (val !== "" && ref.options.length > 0 && ref.optionIndex === -1) {
          ref.moveOptionSelection(1, true); // focus the first selectable option and do not update the input-value
          //ref.toggleOption(ref.options[ref.optionIndex], true); // toggle the focused option
        }
      }
    );
  }
}
